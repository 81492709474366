@import "./app/styling/theme.scss";

html {
  height: 100%;
}

body {
  background-color: $white;
}

.mat-tab-label-container {
  background-color: lightgray !important;
}