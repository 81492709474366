@import "./theme.scss";

@font-face {
  font-family: robotoBlack;
  src: url("./../../assets/fonts/Nunito/Nunito-Black.ttf") format("truetype");
}

@font-face {
  font-family: nunitoReg;
  src: url("./../../assets/fonts/Nunito/Nunito-Regular.ttf") format("truetype");
}

@font-face {
  font-family: nunitoBlack;
  src: local("Nunito-Black"),
    url("./../../assets/fonts/Nunito/Nunito-Black.ttf") format("truetype");
}

@font-face {
  font-family: nunitoLight;
  src: local("Nunito-Light"),
    url("./../../assets/fonts/Nunito/Nunito-Light.ttf") format("truetype");
}

@font-face {
  font-family: nunitoBold;
  src: local("Nunito-Bold"),
    url("./../../assets/fonts/Nunito/Nunito-Bold.ttf") format("truetype");
}

@font-face {
  font-family: arizonia;
  src: local("Arizonia-Regular"),
    url("./../../assets/fonts/Arizonia/Arizonia-Regular.ttf") format("truetype");
}

.cursor-pointer {
  cursor: pointer;
}

/* width */
::-webkit-scrollbar {
  width: 0.1px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px $white;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $grey-text;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $white;
}

::-webkit-scrollbar {
  display: none;
}

body {
  background: $black;
  // width: 1366px;
  // overflow-x: hidden;
  // margin: auto;
}

.custom-slider {
  .ngx-slider {
    .ngx-slider-pointer {
      background-color: deeppink !important;
    }

    .ngx-slider-pointer:after {
      background-color: white !important;
    }

    .ngx-slider-selection {
      background-color: deeppink !important;
    }
  }
}

.custom-dialog-container {
  .mat-dialog-container {
    // margin: 0px;
    padding: 0px;
  }
}

.filter-dialog-container {
  .mat-dialog-container {
    padding: 0px;
    border-radius: 20px;
  }
}

.filter-dialog-depatment {
  .mat-dialog-container {
    // padding: 0px;
    border-radius: 12px 12px 12px 12px;
  }
}

.filter-dialog-location {
  .mat-dialog-container {
    border-radius: 20px;
  }
}

// .checkbox-background {
//   .mat-checkbox-inner-container-no-side-margin {
//     background: white;
//     width: 21px;
//     height: 20px;
//   }
// }

button:disabled {
  background-color: $grey-text !important;
  border-color: $grey-text !important;
  color: $white !important;
  cursor: auto;
}

input[type=file],
/* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button {
  /* chromes and blink button */
  cursor: pointer;
}

.autocomplete-container {
  box-shadow: none !important;
  width: 95%;
  margin-left: 16px;
}

.vendorsite-dialog {
  .mat-dialog-container {
    overflow: inherit !important;
  }
}

.mat-table {
  box-shadow: none !important;
}

.btn:focus {
  box-shadow: none; // unwanted lines are apearing in this class property so to hide it this is placed
}

.search-bar-item {
  .ng-autocomplete {
    width: 287px;
    height: 41px;
    margin-top: 1px;
  }

  .autocomplete-container .input-container input {
    padding: 0px !important;
    margin-left: -6px;
  }

  // .autocomplete-container .not-found{
  //   width: 288px;
  //   margin-left: -13px;
  //   margin-top: 3px;
  // }
}

.datepicker-report {
  .mat-datepicker-content {
    position: relative;
    /* left: 101px; */
    top: 15px;
  }

  .mat-icon-button {
    position: relative;
    top: -7px;
    left: -42px;
    opacity: 0;
  }
}

// .datepicker-report{
//   .mat-form-field{ visibility: hidden; z-index: -999; display: none;}
// }
.error-dialog {
  color: white !important;
  background-color: $black !important;

  .mat-simple-snackbar-action {
    color: #ff1872 !important;
  }
}

div#mat-select-0-panel {
  position: relative;
  top: 40px;
}

.pagination {
  .mat-form-field-wrapper {
    padding: 0px !important;
  }
}

.table-datacheck {
  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.auto-complete {
  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 3px 0 6px 0;
  }
}

.for-time-field {
  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 5px 0 10px 0;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0;
    margin: 0;
  }
}

.pos-sfull-width {

  .mat-form-field-appearance-outline .mat-form-field-wrapper,
  .mat-form-field-wrapper {
    padding: 0px !important;
    background-color: $white;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.selection-calender {
  .mat-datepicker-content .mat-calendar {
    width: 230px !important;
    height: 290px !important;
  }
}

.ngx-slider .ngx-slider-pointer {
  background-color: $theme-primary !important;
}

.autocomplete-container {
  margin-left: 0 !important;

  .input-container {
    input {
      width: 100%;
    }
  }

  // .suggestions-container {
  //   width: 100% !important;
  //   min-width: max-content !important;
  //   border-radius: 2px !important;
  //   top: 50px;
  // }
}
.mat-form-field-appearance-outline .mat-form-field-infix{
  padding: 15px 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 58px;
}
.mat-form-field-type-mat-date-range-input .mat-form-field-infix{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 58px;
}
